import { service } from '@/ajax/request';

export const moxaOrderAsyncApi = () => (
    service({
      url: '/chainure/moxaOrder/asynJob',
      method: 'POST',
    })
  )

export const moxaOrderListApi = (params) => (
    service({
      url: '/chainure/moxaOrder/query',
      method: 'POST',
      params,
    })
  )

export const moxaOrderViewTemplateApi = (params) => (
    service({
      url: '/chainure/moxaOrder/viewPackage',
      method: 'POST',
      params,
    })
  )

export const moxaOrderTemplateListApi = (params) => (
    service({
      url: '/chainure/moxaTempOverview/listByName',
      method: 'POST',
      params,
    })
  )

export const moxaOrderTemplatePublishApi = (params) => (
    service({
      url: '/chainure/moxaTempOverview/publish',
      method: 'POST',
      params
    })
  )

export const moxaOrderTemplateAddApi = (params) => (
    service({
      url: '/chainure/moxaTempOverview/batch',
      method: 'POST',
      params
    })
  )

export const moxaOrderTemplateAcupointOptionApi = (params) => (
    service({
      url: '/chainure/moxaTempOverview/listAcupoint',
      method: 'POST',
      params
    })
  )

export const moxaOrderTemplateMoxaOptionApi = (acupoint_id) => (
    service({
      url: `/chainure/moxaTempOverview/listMoxa/${acupoint_id}`,
      method: 'POST',
    })
  )

export const moxaPackageTemplateConApi = (params) => (
    service({
      url: `/chainure/moxaPackageContent/list/${params.tempId}`,
      method: 'POST',
    })
  )

export const moxaPackageEnableTemplateApi = (id) => (
    service({
      url: `/chainure/moxaTempOverview/enableTemp/${id}`,
      method: 'POST',
    })
  )

<template>
    <div class="content-box">
      <div class="content-header">
        <el-select class="input" clearable v-model="queryForm.status" placeholder="启禁状态">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input
            v-model="queryForm.name"
            maxlength="50"
            class="input"
            clearable
            placeholder="请输入模板名称"
        ></el-input>
        <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
        <el-button @click="add" type="plain" class="cotent-btn-big" icon="el-icon-plus">添加</el-button>
            <!-- <el-button @click="publishAll" :loading="publishAllLoading" type="plain" class="cotent-btn-big" icon="el-icon-upload">全部发布</el-button> -->
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" border
          v-loading="loading">
          <el-table-column prop="id" align="center" width="" label="ID">
          </el-table-column>
          <el-table-column prop="moxaTempName" align="center" label="模板名称">
            <template #default="{row}">
                <span @click="viewTemplateDetail(row)" class="link">{{row.moxaTempName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="moxaPackageContentList" align="center" label="模板内容">
            <template #default="{row}">
              <p class="ellipsis-text" style="text-align: left" v-for="item in row.moxaPackageContentList?.slice(0, 3)" :key="item.id">
                {{item.acupointName}}：{{`${item.moxibustionName}${item.time}分钟`}}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="totalTimes" align="center" label="艾灸总时长（分钟）">
          </el-table-column>
          <el-table-column prop="publishStatus" align="center" label="发布状态">
            <template #default="{row}">
              <span class="success-color" v-if="row.publishStatus == 1">成功</span>
              <span class="danger-color" v-if="row.publishStatus == 0">失败</span>
            </template>
          </el-table-column>
          <el-table-column prop="createBy" align="center" label="启禁状态">
            <template #default="{row}">
              {{!row.operationStatus ? '已禁用' : '已启用'}}
            </template>
          </el-table-column>
          <el-table-column prop="createBy" align="center" label="发布人">
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="发布时间">
          </el-table-column>
          <el-table-column prop="remark" align="center" label="操作">
            <template #default="{row}">
                <el-button v-if="row.publishStatus == 1" :class="{ 
                'disable-btn': row.operationStatus == moxibustionStatusEnum?.Enable,
                'enable-btn': row.operationStatus == moxibustionStatusEnum?.Disable,
                }"
                type="text" @click="handleUpdateStatus(row)">{{ row.operationStatus == moxibustionStatusEnum?.Enable ? "启用" : "禁用"}}</el-button>
                <el-button v-if="row.publishStatus == 0" type="text" @click="publishRow(row)">发布</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>

        <el-dialog
          title="新增" 
          :close-on-click-modal="false"
          :visible.sync="addTemplateFlag"
          :before-close="cancel"
          width="800px"
          >
          <el-form :model="formData" :rules="formData.rules" ref="formRef" class="table-form">
              <el-form-item prop="tempName" label="模板名称">
                <template #label>
                  模板名称
                </template>
                <el-input v-model="formData.tempName" placeholder="请输入模板名称" maxlength="30"></el-input>
              </el-form-item>
              <el-form-item label="模板内容" required>
                <template #label>
                  模板内容
                  <el-button type="primary" plain class="add-row-btn" :disabled="formData.tableData?.length >= 30" @click="addRow" size="small">新增</el-button>
                </template>
                <el-table
                border
                class="formTable"
                max-height="500"
                :data="formData.tableData"
                style="width: 100%">

                <el-table-column prop="acupointId" label="穴位" width="" align="center">
                  <template #header>
                    <div>
                      <span style="color: red">*</span>
                      <span>穴位</span>
                    </div>
                  </template>
                  <template #default="{row, $index}">
                    <el-form-item :prop="`tableData.${$index}.acupointId`" :rules="formData.rules.acupointId">
                      <el-select  @change="acupointIdChange(row)" size="small" v-model="row.acupointId" filterable placeholder="请选择">
                        <el-option
                          v-for="item in xwOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="moxibustionId" label="灸法" width="" align="center">
                  <template #header>
                    <div>
                      <span style="color: red">*</span>
                      <span>灸法</span>
                    </div>
                  </template>
                  <template #default="{row, $index}">
                    <el-form-item :prop="`tableData.${$index}.moxibustionId`" :rules="formData.rules.moxibustionId">
                      <el-select size="small" :disabled="!row.acupointId" v-model="row.moxibustionId" @focus="moxibustionIdChange(row)" placeholder="请选择">
                        <el-option
                          v-for="item in jfOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="时长(分钟)" width="" align="center">
                  <template #header>
                    <div>
                      <span style="color: red">*</span>
                      <span>时长(分钟)</span>
                    </div>
                  </template>
                  <template #default="{row, $index}">
                    <el-form-item :prop="`tableData.${$index}.time`" :rules="formData.rules.time">
                      <el-input-number size="small" v-model="row.time" :min="1" :max="30" :precision="0" :controls="false" placeholder="请输入"></el-input-number>
                    </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="操作" width="100" align="center">
                  <template #default="{row, $index}">
                    <el-button @click="delRow(row, $index)" type="text">删除</el-button>
                  </template>
                </el-table-column>
                </el-table>
              </el-form-item>


          </el-form>
          <div class="total-times">
            总时长（分钟）：{{totalCountTimes}}
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button :loading="saveLoading" type="primary" @click="save">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <el-dialog
          :title="dialogTitle"
          :visible.sync="showTemplateDetail"
          width="700px"
          :close-on-click-modal="false"
          >
          <TemplateDetail :api="api" :packageData="packageData" type="template" />
        </el-dialog>
    </div>
</template>
<script>
import { Watch, Mixins, Component } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import TemplateDetail from "./component/templateDetail.vue";

import { MoxibustionStatusEnum } from "@/enum/moxibustion.enum";

import { moxaOrderTemplateListApi, moxaOrderTemplatePublishApi, moxaOrderTemplateAddApi, moxaOrderTemplateAcupointOptionApi, moxaOrderTemplateMoxaOptionApi, moxaPackageTemplateConApi, moxaPackageEnableTemplateApi } from "@/api/moxibustion";

import { cloneDeep } from "lodash"

const initRow = {acupointId: "", moxibustionId: "", time: undefined}

@Component({
  components: {TemplateDetail}
})
  export default class BuildStoreList extends Mixins(loading, tablePagination, Resize) {
    moxibustionStatusEnum = MoxibustionStatusEnum
    api = moxaPackageTemplateConApi;
    queryForm = {
      name: "",
      status: "",  // 默认传-1 0代表禁用，1代表启用
    };

    tableData = [];
    itemTotal = 0;

    showTemplateDetail = false;
    dialogTitle = "";
    packageData = "";

    addTemplateFlag = false;
    formData = {
      tempName: "",
      rules: {
        tempName: { required: true, message: "请输入模板名称", trigger: ['blur', 'change']},
        acupointId: { required: true, message: '请选择穴位', trigger: ['blur', 'change'] },
        moxibustionId: { required: true, message: '请选择灸法', trigger: ['blur', 'change'] },
        time: { required: true, message: '请输入时长', trigger: ['blur'] },
      },
      tableData: [{ acupointId: "", moxibustionId: "", time: "" }],
    }
    xwOptions = [];
    jfOptions = [];
    sjOptions = [];
    statusOptions = [{ label: "已启用", value: 1 }, { label: "已禁用", value: 0 }]

    publishAllLoading = false;

    saveLoading = false;

    templateDetailList = [];

    get totalCountTimes() {
      const count = this.formData.tableData?.reduce((acc, cur) => {
        acc = acc + (cur.time ? Number(cur.time) : 0);
        return acc;
      }, 0)
      console.log("count", count)
      return count;
    }

    mounted() {
        this.windowResize(310)
        this.getData();
        this.getOptions();
    }

    getOptions() {
      moxaOrderTemplateAcupointOptionApi().then((res) => {
        this.xwOptions = res.data || [];
        this.xwOptions?.forEach((op) => {
          op.value = op.code;
          op.label = op.name;
        })
      })
    }

    publishRow(row) {
      const { moxaPackageContentList, moxaTempName } = row;
      const content = moxaPackageContentList?.map((el) => ({ acupoint: el.acupointId, model: el.moxibustionId, time: el.time }))
      moxaOrderTemplatePublishApi({ name: moxaTempName, content }).then(() => {
        this.$message.success("发布成功");
      }).finally(() => this.publishAllLoading = false);
    }

    // 搜索
    handleSearch() {
      this.resetPages();
      this.getData();
    }

    getData() {
      this.showLoading();
      const queryForm = {
        ...this.queryForm,
        status: [0 ,1, "0", "1"].includes(this.queryForm.status) ? this.queryForm.status : -1,
      }
      moxaOrderTemplateListApi({
        ...queryForm,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      }).then(res => {
        this.hideLoading();
        this.tableData = res.data.list || [];
        this.tableData.forEach((el) => {
          el.totalTimes = el.moxaPackageContentList.reduce((calc, curr) => {
            return calc + curr.time;
          }, 0)
        })
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      });
    }

    //重置分页参数
    resetPages() {
      this.currentPage = 1;
    }
  
    // 搜索
    handleSearch() {
      this.resetPages();
      this.getData();
    }

    viewTemplateDetail(row){
      this.showTemplateDetail = true;
      this.dialogTitle = row.moxaTempName;
      this.packageData = row.id;
    }

    add() {
      this.addTemplateFlag = true;
      this.formData.tableData = [cloneDeep(initRow)]
    }

    addRow() {
      this.formData.tableData.push(cloneDeep(initRow));
      this.$nextTick(() => {
        const formTableEL = document.querySelector(".formTable");
        const tableWrapper = formTableEL?.querySelector(".el-table__body-wrapper");
        if (tableWrapper) {
          tableWrapper.scrollTop = tableWrapper.scrollHeight;
        }
      })
    }

    delRow(row, index) {
      if (this.formData.tableData?.length <= 1) return;
      this.formData.tableData.splice(index, 1);
    }

    save() {
      this.$refs["formRef"].validate().then(() => {
        const params = {
          tempName: this.formData.tempName,
          dtoList: this.formData.tableData || [],
        }
        this.saveLoading = true;
        moxaOrderTemplateAddApi(params).then((res) => {
          this.getData();
          this.cancel();
        }).finally(() => {
          this.saveLoading = false;
        })
      })
    }

    cancel() {
      this.$refs["formRef"].resetFields();
      this.addTemplateFlag = false;
    }

    beforeClose() {
      this.showTemplateDetail = false;
    }

    getMoxaOption(acupoint_id) {
      moxaOrderTemplateMoxaOptionApi(acupoint_id).then((res) => {
        let options = res.data || [];
        options?.forEach((op) => {
          op.value = op.code;
          op.label = op.name;
        })
        this.jfOptions = cloneDeep(options);
      }).catch(() => {
        this.jfOptions = [];
      })
    }

    acupointIdChange(row) {
      row.moxibustionId = "";
    }

    moxibustionIdChange(row) {
      this.getMoxaOption(row.acupointId);
    }

    /**
     * @description 启禁二次弹窗
     */
    handleUpdateStatus(row) {
        const { operationStatus, moxaTempName }  = row;
        this.$confirm(`是否${operationStatus == MoxibustionStatusEnum?.Enable ? "启用" : "禁用"}云端模板[${moxaTempName}]`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
        this.doUpdateStatus(row.id);
        })
    }
    doUpdateStatus(id) {
      moxaPackageEnableTemplateApi(id).then(() => {
        this.getData();
        this.$message.success("操作成功")
      })
    }
  }
</script>
<style lang="scss" scoped>
@import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}

.dialogInput {
  width: 100%;
}

.dialog-footer{
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.store-saas-name{
  color: $primary;
  margin-left: 5px;
}
.plan-dialog-content{
    max-height: calc(100vh - 500px);
    overflow: hidden;
    display: flex;
    .left-step{
        width: 200px;
        height: 100%;
        overflow-y: auto;
    }
    .right-content{
        flex: 1;
    }
}
.tag{
    margin-right: 5px;
}
.link {
    color: $primary;
    cursor: pointer;
}
.el-input-number {
  width: 100%;
  input {
    text-align: left!important;;
  }
}
.add-row-btn{
  position: absolute;
  right: 20px;
  z-index: 2;
}
.total-times{
  text-align: right;
}
</style>
<style lang="scss">
.table-form .el-table .el-table__row .el-table__cell .el-form-item__error{
  text-align: left;
  position: relative;
}
.table-form .el-table thead .el-table__cell{
  padding: 0;
}
.table-form .el-table .el-table__row .el-table__cell {
  vertical-align: top;
}

</style>